<div class="background-image-container">
  <div class="container">
    <!-- <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
          <h1 class="display-3 dd">DSpace 7</h1>
          <p class="lead">DSpace is the world leading open source repository platform that enables
            organisations to:</p>
        </div>
      </div>
      <ul>
        <li>easily ingest documents, audio, video, datasets and their corresponding Dublin Core
          metadata
        </li>
        <li>open up this content to local and global audiences, thanks to the OAI-PMH interface and
          Google Scholar optimizations
        </li>
        <li>issue permanent urls and trustworthy identifiers, including optional integrations with
          handle.net and DataCite DOI
        </li>
      </ul>
      <p>Join an international community of <a href="https://wiki.lyrasis.org/display/DSPACE/DSpace+Positioning" target="_blank">leading institutions using DSpace</a>.</p>
      <p>The test user accounts below have their password set to the name of this
        software in lowercase.</p>
      <ul>
        <li>Demo Site Administrator = dspacedemo+admin@gmail.com</li>
        <li>Demo Community Administrator = dspacedemo+commadmin@gmail.com</li>
        <li>Demo Collection Administrator = dspacedemo+colladmin@gmail.com</li>
        <li>Demo Submitter = dspacedemo+submit@gmail.com</li>
      </ul>
    </div> -->

    <div class="banner-text">
      <h1>Flood Resilience Portal</h1>
      <p>A platform managed by the Zurich Flood Resilience Alliance to give those who live in or work with flood-prone communities easy access to knowledge needed to build resilience to floods.</p>
    </div>
  </div>
  <picture class="background-image test">
    <!-- <source type="image/webp" srcset="assets/dspace/images/banner.webp 2000w, assets/dspace/images/banner-half.webp 1200w, assets/dspace/images/banner-tall.webp 768w">
    <source type="image/jpg" srcset="assets/dspace/images/banner.jpg 2000w, assets/dspace/images/banner-half.jpg 1200w, assets/dspace/images/banner-tall.jpg 768w"> -->
    <img alt="" [src]="'assets/dspace/images/banner.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
  <!-- <small class="credits">Photo by <a href="https://www.pexels.com/@inspiredimages">@inspiredimages</a></small> -->
</div>
