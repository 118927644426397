<div class="main_wrapper">
    <div class="img_wrapper">
        <img alt="" [src]="'assets/dspace/images/main_logo.png'"/>
    </div>
    <div class="text_wrapper">
        <a class="test" *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/communities/' + object.id]" class="lead">
            {{object.name}}
        </a>
        <span *ngIf="linkType == linkTypes.None" class="lead">
            {{object.name}}
        </span>
        <div *ngIf="object.shortDescription" class="text-muted asd abstract-text">
            {{object.shortDescription}}
        </div>
    </div>
</div>